import Image from 'next/image'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Text from './Text'
import BorderBox from './BorderBox'
import Quote from './Quote'

const BrandedQuote = (props) => {
  const { quoteImage, quoteText, quoteLogo, quoteDate, className, size, testID } = props
  return (
    <QuoteContainer className={className} testID={testID}>
      <QuoteContent testID={'quote'}>
        {
          quoteImage ?
            <Image
              src={quoteImage?.url}
              width={quoteImage?.dimensions?.width}
              height={quoteImage?.dimensions?.height}
              alt={quoteImage?.alt}
            /> :
            (quoteText && <Quote size={size} testID="quote-text">
              {quoteText}
            </Quote>)
        }
      </QuoteContent>
      {quoteLogo &&
        <Image
          src={quoteLogo?.url}
          width={quoteLogo?.dimensions?.width}
          height={quoteLogo?.dimensions?.height}
          alt={quoteLogo?.alt}
        />
      }
      {quoteDate && <StyledDate size="eyebrow" testID={'quote-date'}>
        {quoteDate}
      </StyledDate>}
    </QuoteContainer>
  )
}

const QuoteContainer = styled(BorderBox)`
  padding: 30px;
  margin: 0 0 80px;
  

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    padding: 60px;
  }

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    padding: 72px 118px;
  }
`

const QuoteContent = styled.div`
  max-width: 788px;
  margin: 0 auto;
  color: ${ ({ theme }) => theme.colors.azureDark };
`

const StyledDate = styled(Text)`
  margin-top: 15px;
  margin-bottom: 0;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    margin-top: 30px;
  }
`

BrandedQuote.defaultProps = {
  quoteText: '',
  className: '',
  size: 'large',
  testID: 'quote',
  quoteDate: '',
  quoteImage: null,
  quoteLogo: null,
}

BrandedQuote.propTypes = {
  className: PropTypes.string,
  quoteDate: PropTypes.string,
  quoteImage: PropTypes.oneOfType([PropTypes.object]),
  quoteLogo: PropTypes.oneOfType([PropTypes.object]),
  quoteText: PropTypes.node,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  testID: PropTypes.string,
}

export default BrandedQuote
